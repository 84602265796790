<template>
    <div class="center-container">
        <v-card class="center-card" elevation="4" style="margin-bottom: 100px;">
            <div class="d-flex justify-end pt-3 pl-3">
                <span class="text-center flex-grow-1"
                    style="font-weight: bold; font-size: 30px; font-family: Times, serif; color: #062a4e;">עריכת מסעדה
                </span>
            </div>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="itemEdited.name" label="שם"
                                :rules="[v => !!v || 'שדה חובה']"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="itemEdited.address" label="רחוב"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-combobox :items="citiesItems" v-model="itemEdited.city_web" label="עיר"></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-combobox :items="countriesItems" v-model="itemEdited.country_web"
                                label="מדינה ( רק בחול)"></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-autocomplete :items="zonesItems" v-model="itemEdited.zone_web"
                                label="אזור"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="itemEdited.website" label="אתר"></v-text-field>
                        </v-col><v-col cols="12" sm="6">
                            <v-text-field v-model="itemEdited.phone" @input="filterNumeric"
                                label="טלפון"></v-text-field>
                        </v-col><v-col cols="12" sm="6">
                            <v-autocomplete :items="categoriesItems" v-model="itemEdited.category_web"
                                label="קטגוריה"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-autocomplete :items="subCategoriesItems" multiple v-model="itemEdited.sub_category_web"
                                label="תת קטגוריה"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-select :items="typesItems" multiple v-model="itemEdited.type_web" label="סוג"></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="itemEdited.details" label="מידע על המסעדה"></v-text-field>
                        </v-col>
                        <span style="font-weight: bold; font-size: large;">כשרות</span>
                        <v-col cols="12" sm="4">
                            <v-autocomplete :items="kashrutItems" v-model="itemEdited.kashrut.kashrut_name_web"
                                label="כשרות"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="4" v-if="itemEdited.kashrut.kashrut_name_web === 'badatz_local'">
                            <v-text-field v-model="itemEdited.kashrut.local_kashrut_name"
                                label="כשרות מקומית"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="itemEdited.kashrut.kashrut_details"
                                label="פרטים נוספים על הכשרות"></v-text-field>
                        </v-col>
                        <span style="font-weight: bold; font-size: large;">שעות פתיחה</span>
                        <v-col cols="auto" sm="auto">
                            <span>יום א'</span>
                            <v-text-field v-model="itemEdited.open_hours.sunday.open" type="time"
                                label="פתיחה"></v-text-field>
                            <v-text-field v-model="itemEdited.open_hours.sunday.close" type="time"
                                label="שעות סגירה"></v-text-field>
                        </v-col>
                        <v-col cols="auto" sm="auto">
                            <span>יום ב'</span>
                            <v-text-field v-model="itemEdited.open_hours.monday.open" type="time"
                                label="פתיחה"></v-text-field>
                            <v-text-field v-model="itemEdited.open_hours.monday.close" type="time"
                                label="שעות סגירה"></v-text-field>
                        </v-col>
                        <v-col cols="auto" sm="auto">
                            <span>יום ג'</span>
                            <v-text-field v-model="itemEdited.open_hours.tuesday.open" type="time"
                                label="פתיחה"></v-text-field>
                            <v-text-field v-model="itemEdited.open_hours.tuesday.close" type="time"
                                label="שעות סגירה"></v-text-field>
                        </v-col>
                        <v-col cols="auto" sm="auto">
                            <span>יום ד'</span>
                            <v-text-field v-model="itemEdited.open_hours.wednesday.open" type="time"
                                label="פתיחה"></v-text-field>
                            <v-text-field v-model="itemEdited.open_hours.wednesday.close" type="time"
                                label="שעות סגירה"></v-text-field>
                        </v-col>
                        <v-col cols="auto" sm="auto">
                            <span>יום ה'</span>
                            <v-text-field v-model="itemEdited.open_hours.thursday.open" type="time"
                                label="פתיחה"></v-text-field>
                            <v-text-field v-model="itemEdited.open_hours.thursday.close" type="time"
                                label="שעות סגירה"></v-text-field>
                        </v-col>
                        <v-col cols="auto" sm="auto">
                            <span>יום ו'</span>
                            <v-text-field v-model="itemEdited.open_hours.friday.open" type="time"
                                label="פתיחה"></v-text-field>
                            <v-text-field v-model="itemEdited.open_hours.friday.close" type="time"
                                label="שעות סגירה"></v-text-field>
                        </v-col>
                        <v-col cols="auto" sm="auto">
                            <span>מוצ"ש</span>
                            <v-text-field v-model="itemEdited.open_hours.saturday.open" type="time"
                                label="פתיחה"></v-text-field>
                            <v-text-field v-model="itemEdited.open_hours.saturday.close" type="time"
                                label="שעות סגירה"></v-text-field>
                        </v-col>
                        <v-col cols="12" style="display: flex;">
                            <v-checkbox v-model="itemEdited.open_hours.only_weekend" :label="weekendTitle"
                                style="margin-bottom: 0px !important; margin-top: 0px !important;">
                            </v-checkbox>
                            <v-checkbox v-model="itemEdited.open_hours.isTemporarilyClosed" :label="tempClosedTitle"
                                style="margin-bottom: 0px !important; margin-top: 0px !important; margin-right: 20px;">
                            </v-checkbox>
                        </v-col>
                        <span style="font-weight: bold; font-size: large;">מיקום</span>
                        <v-col cols="12">
                            <v-btn @click="chooseDeviceLocation()" rounded>בחר את מיקום המכשיר</v-btn>
                        </v-col>
                        <span style="font-weight: bold; font-size: large;">או הוסף מהמפה</span>
                        <v-col cols="12">
                            <!-- Search Field -->
                            <GmapPlaceInput @place_changed="setPlace" ref="gmapInput" />
                            <!-- Google Maps -->
                            <GmapMap ref="gmapRef" :center="center" :zoom="12" style="width: 100%; height: 400px"
                                @click="mapClicked">
                                <GmapMarker :position="marker.position" @click="markerClicked"></GmapMarker>
                            </GmapMap>
                        </v-col>
                        <span style="font-weight: bold; font-size: large;">העלאת תמונות</span>
                        <v-row>
                            <v-col cols="auto" sm="6" class="d-flex justify-end">

                                <div v-if="!itemEdited.logo" style="max-width: 100%; width: 150px; height: 150px;">
                                    <v-file-input label="לוגו" filled height="150" prepend-icon=""
                                        v-model="itemEdited.logo" accept="image/*"
                                        prepend-inner-icon="mdi-camera"></v-file-input>
                                </div>
                                <div v-else style="height: 150px; width: 150px; position: relative;">
                                    <div
                                        style="max-width: 100%; width: 150px; height: 100%; background-color: rgba(0, 0, 0, 0.06);">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" style=" z-index: 10; cursor: pointer;"
                                                    @click="itemEdited.logo = null">
                                                    mdi-delete
                                                </v-icon>
                                            </template>
                                            <span>Delete Image</span>
                                        </v-tooltip>
                                        <span style="margin-inline-start: 10px;">לוגו</span>
                                        <img contain id="logoPreview" v-if="isString(itemEdited.logo)"
                                            style="display: block; max-width: 100%; max-height: 100%; width: auto; height: auto; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                                            :src="host + '/logo' + itemEdited.logo" alt="Preview"
                                            transition="scale-transition">
                                        <img contain id="logoPreview" v-else
                                            style="display: block; max-width: 100%; max-height: 100%; width: auto; height: auto; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                                            :src="showFileInput(itemEdited.logo, 'logoPreview')" alt="Preview"
                                            transition="scale-transition">
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="auto" sm="6">
                                <div v-if="!itemEdited.kashrut.certificate"
                                    style="max-width: 100%; width: 150px; height: 150px;">
                                    <v-file-input label="תעודת כשרות" filled height="150" prepend-icon=""
                                        v-model="itemEdited.kashrut.certificate" accept="image/*"
                                        prepend-inner-icon="mdi-camera"></v-file-input>
                                </div>
                                <div v-else style="height: 150px; width: 150px; position: relative;">
                                    <div
                                        style="max-width: 100%; width: 150px; height: 100%; background-color: rgba(0, 0, 0, 0.06);">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" style=" z-index: 10; cursor: pointer;"
                                                    @click="itemEdited.kashrut.certificate = null">
                                                    mdi-delete
                                                </v-icon>
                                            </template>
                                            <span>Delete Image</span>
                                        </v-tooltip>
                                        <span style="margin-inline-start: 10px;">תעודת כשרות</span>
                                        <img contain id="logoPreview" v-if="isString(itemEdited.kashrut.certificate)"
                                            style="display: block; max-width: 100%; max-height: 100%; width: auto; height: auto; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                                            :src="host + '/certificates' + itemEdited.kashrut.certificate" alt="Preview"
                                            transition="scale-transition">
                                        <img contain id="certificatePreview" v-else
                                            style="display: block; max-width: 100%; max-height: 100%; width: auto; height: auto; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                                            :src="showFileInput(itemEdited.kashrut.certificate, 'certificatePreview')"
                                            alt="Preview" transition="scale-transition">
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- handle upload picturs -->
                        <v-row>
                            <v-col v-for="(picture, index) in arrayPictures" cols="auto" sm="3">
                                <div style="max-width: 100%; width: 150px; height: 150px;"
                                    v-if="!itemEdited.pictures[index]">
                                    <v-file-input :label="'תמונה ' + (index + 1)" filled height="150" prepend-icon=""
                                        v-model="itemEdited.pictures[index]" prepend-inner-icon="mdi-camera"
                                        accept="image/*">
                                    </v-file-input>
                                </div>
                                <div v-else style="height: 150px; width: 150px; position: relative;">
                                    <div
                                        style="max-width: 100%; width: 150px; height: 100%; background-color: rgba(0, 0, 0, 0.06);">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" style=" z-index: 10; cursor: pointer;"
                                                    @click="handleDelete(index)">
                                                    mdi-delete
                                                </v-icon>
                                            </template>
                                            <span>Delete Image</span>
                                        </v-tooltip>
                                        <span style="margin-inline-start: 10px;">תמונה {{ (index + 1) }}</span>
                                        <img contain id="picturePreview" v-if="isString(itemEdited.pictures[index])"
                                            style="display: block; max-width: 100%; max-height: 100%; width: auto; height: auto; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                                            :src="host + '/pictures' + itemEdited.pictures[index]" alt="Preview"
                                            transition="scale-transition">
                                        <img contain id="picturePreview" v-else
                                            style="display: block; max-width: 100%; max-height: 100%; width: auto; height: auto; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                                            :src="showFileInput(itemEdited.pictures[index], 'picturePreview')"
                                            alt="Preview" transition="scale-transition">
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-col cols="12">
                            <span style="font-weight: bold; font-size: medium;">המידע יישלח לאישור טרם השמירה</span>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center" style="margin-top: 20px;">
                            <v-btn color="#062a4e" dark @click="sendRestaurant()" rounded>שלח</v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    </div>
</template>
<script>
import SnackBar from '@/components/widgets/snackBar'
import Constants from '@/util/GetConstant'
import axios from 'axios'
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service.js'

export default {
    components: {
        SnackBar
    },
    data() {
        return {
            progressShow: false,
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
            host: "",
            itemEdited: {},
            objKashruyot: {},
            objTypes: {},
            objCategories: {},
            objSubCategories: {},
            objZones: {},
            objCountries: {},
            objCities: {},
            arrayPictures: ["", "", "", ""],
            weekendTitle: "פתוח רק בסופ\"ש",
            tempClosedTitle: "סגור זמנית",
            center: { lat: 32.0879976, lng: 34.8384455 },
            marker: {
                position: { lat: 32.0879976, lng: 34.8384455 }
            }

        }
    },
    methods: {

        async sendRestaurant() {

            if (!this.itemEdited.location.latitude) {
                return this.showSnackBar("לא נבחר מיקום", "red");
            }
            try {
                this.progressShow = true;
                this.itemEdited.city_web = this.itemEdited.city_web.value;
                this.itemEdited.country_web = this.itemEdited.country_web ? this.itemEdited.country_web.value : "";
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/user/edit_restaurant`;
                const formData = new FormData();
                formData.append('logo', this.itemEdited.logo ? this.itemEdited.logo : "");
                formData.append('cert', this.itemEdited.kashrut.certificate ? this.itemEdited.kashrut.certificate : "");
                formData.append('picture1', this.itemEdited.pictures[0] ? this.itemEdited.pictures[0] : "");
                formData.append('picture2', this.itemEdited.pictures[1] ? this.itemEdited.pictures[1] : "");
                formData.append('picture3', this.itemEdited.pictures[2] ? this.itemEdited.pictures[2] : "");
                formData.append('picture4', this.itemEdited.pictures[3] ? this.itemEdited.pictures[3] : "");
                // Destructure the fields you want to omit and use the rest operator to collect the remaining fields into a new object.
                const { logo, pictures, kashrut, ...otherDetails } = this.itemEdited;
                // If you also want to omit `kashrut.certificate`, you can destructure it further.
                const { certificate, ...remainingKashrut } = kashrut;
                // Now, `otherDetails` contains all fields from `itemEdited` except `logo`, `pictures`, and `kashrut.certificate`.
                // Add `kashrut` back into `otherDetails` without the `certificate`.
                otherDetails.kashrut = remainingKashrut;
                // Convert otherDetails object to JSON string
                const otherDetailsJSON = JSON.stringify(otherDetails);
                formData.append('otherDetails', otherDetailsJSON);
                const response = await axios.post(api, formData, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'multipart/form-data'
                    }
                });
                this.progressShow = false;
                if (response.status === 200) {
                    this.showSnackBar("הפרטים נשלחו בהצלחה!", "green");
                } else {
                    // Handle other status codes
                    this.showSnackBar("Received unexpected status code: " + response.status, "yellow");
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error save new restaurant: " + error, "red");
            }
        },
        async getRestDetailsById() {
            try {
                this.progressShow = true;
                const nameSearchJSON = JSON.stringify({ idSelected: this.$route.params._id });
                const token = localStorage.getItem("token");
                console.log(token);
                let api = process.env.VUE_APP_BASE_URL + `/user/get_rest_for_edit`;

                const res = await fetch(api, ApiServices.requestOptions("POST", nameSearchJSON, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.itemEdited = jsonObject;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get restaurants list: " + error, "red");
            }
        },
        chooseDeviceLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        this.showPosition(position);
                        { true }
                    },
                    error => {
                        this.showSnackBar("שגיאה בקבלת המיקום: יש להפעיל את המיקום בלחיצה על האייקון למעלה בשורת הכתובת", "red");
                    }
                );
            } else {
                this.showSnackBar("Geolocation is not supported", "red");
            }
        },
        showPosition(position) {
            this.itemEdited.location.latitude = position.coords.latitude;
            this.itemEdited.location.longitude = position.coords.longitude;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        async getConstants() {
            const constants = await Constants.getConstant();
            if (!constants) {
                this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
            }
            this.objKashruyot = constants.kashruyot
            this.objTypes = constants.types;
            this.objCategories = constants.categories;
            this.objSubCategories = constants.sub_categories;
            this.objZones = constants.zones
            this.objCountries = constants.countries
            this.objCities = constants.cities
        },
        initializeEmptyObj() {
            this.itemEdited = {
                name: "",
                address: "",
                city: "",
                country: "",
                zone: "",
                zone_web: "",
                type: [],
                type_web: [],
                phone: "",
                website: "",
                category: "",
                category_web: "",
                sub_category: [],
                sub_category_web: [],
                details: "",
                pictures: [],
                logo: null,
                kashrut: { kashrut_name: "", kashrut_name_web: "", local_kashrut_name: "", kashrut_details: "", certificate: null },
                open_hours: {
                    sunday: { open: "", close: "" }, monday: { open: "", close: "" }, tuesday: { open: "", close: "" },
                    wednesday: { open: "", close: "" }, thursday: { open: "", close: "" }, friday: { open: "", close: "" }, saturday: { open: "", close: "" },
                    only_weekend: false,
                    isTemporarilyClosed: false
                },
                location: { latitude: "", longitude: "" },
                userId: Auth.getUserId(),
            }
        },
        setPlace(place) {
            this.marker.position = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };
            this.center = this.marker.position;
        },
        markerClicked(event) {
            alert(`Marker clicked! Latitude: ${event.latLng.lat()} Longitude: ${event.latLng.lng()}`);
        },
        mapClicked(event) {

            this.marker.position = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            };

            this.itemEdited.location.latitude = event.latLng.lat();
            this.itemEdited.location.longitude = event.latLng.lng();
        },
        handleDelete(index) {
            this.$set(this.itemEdited.pictures, index, null)

        },
        showFileInput(newFile, id) {
            setTimeout(() => {
                var image = document.getElementById(`${id}`);
                // Set the src attribute to the object URL created from the file
                image.src = URL.createObjectURL(newFile);
                // Remember to revoke the object URL later to free up memory
                image.onload = () => URL.revokeObjectURL(image.src);
            }, 300);

        },
        isString(variable) {
            return typeof variable === 'string';
        },
        filterNumeric(value) {
            console.log(value);
            // Replace non-numeric characters with an empty string
            // this.itemEdited.phone = value.replace(/-/g, '');

            this.itemEdited.phone = value.replace(/\D+/g, '');
        }
    },
    computed: {
        kashrutItems() {
            return Object.entries(this.objKashruyot).map(([key, kashrut]) => ({
                value: key, // You can use the key as the value
                text: kashrut[this.$i18n.locale] || kashrut.en // or value.en for English
            }));
        },
        zonesItems() {
            return Object.entries(this.objZones).map(([key, zone]) => ({
                value: key, // You can use the key as the value
                text: zone[this.$i18n.locale] || zone.en // or value.en for English
            }));
        },
        typesItems() {
            return Object.entries(this.objTypes).map(([key, type]) => ({
                value: key, // You can use the key as the value
                text: type[this.$i18n.locale] || type.en // or value.en for English
            }));
        },
        categoriesItems() {
            return Object.entries(this.objCategories).map(([key, category]) => ({
                value: key, // You can use the key as the value
                text: category[this.$i18n.locale] || category.en // or value.en for English
            }));
        },
        subCategoriesItems() {
            return Object.entries(this.objSubCategories).map(([key, subCategory]) => ({
                value: key, // You can use the key as the value
                text: subCategory[this.$i18n.locale] || subCategory.en // or value.en for English
            }));
        },
        countriesItems() {
            return Object.entries(this.objCountries).map(([key, country]) => ({
                value: key, // You can use the key as the value
                text: country[this.$i18n.locale] || city.en // or value.en for English
            }));
        },
        citiesItems() {
            return Object.entries(this.objCities).map(([key, city]) => ({
                value: key, // Use the city key as the value
                text: city[this.$i18n.locale] || city.en // Use localized city name
            }));
        }
    },
    created() {
        this.initializeEmptyObj();
        this.getConstants();
        this.host = process.env.VUE_APP_BASE_URL;
        // change maps input style
        this.$nextTick(() => {
            const input = this.$refs.gmapInput.$el.querySelector('input');
            if (input) {
                input.style.backgroundColor = '#f6f6f6';
                input.style.padding = '10px';
                input.style.border = '1px solid rgb(201, 191, 191)';
                input.style.width = '300px';
                input.style.maxWidth = '100%';
            }
        });
        if (this.$route.params._id) {
            this.getRestDetailsById();
        }
    },
    watch: {

    }

};
</script>
<style scoped>
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    margin-right: 5px;
    margin-left: 5px;

}

.center-card {
    max-width: 900px;
    width: 100%;
    padding-bottom: 40px;
    margin-left: 5px;
    margin-right: 5px;
}

.v-file-input .v-label {
    align-self: center;
}


#fileInput {
    display: none;
}

/* Style the label as a square */
.label1 {
    display: block;
    width: 100px;
    /* Adjust as needed */
    height: 100px;
    /* Adjust as needed */
    background-color: #f4f4f4;
    border: 2px dashed #333;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Arial, sans-serif;
}

label:hover {
    background-color: #ddd;
}
</style>