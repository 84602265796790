<template >
  <v-container style="margin-bottom: 70px;">
    <v-row class="mt-10 d-flex justify-center">
      <v-col cols="6" style="max-width: 500px;">
        <v-card class="pa-4" style="box-shadow: 0 0 10px #fff;">
          <v-img :src="require('../../assets/default_logo.png')" class="my-3 mx-auto" contain
            :style="{ 'width': isResponsive ? '60%' : '100%' }" height="200" />
          <div>
            <span class=" d-flex justify-center">
              <h1 style="color:#1e517b">{{ $t('auth.register_title') }}</h1>
            </span>
          </div>
          <v-form @submit.prevent="submitHandler" ref="form">
            <v-card-text>
              <v-text-field v-model="userDetails.name" :label="'*' + $t('auth.name')" type="text"
                :rules="[v => !!v || 'שדה חובה']" :placeholder="$t('auth.name')" required></v-text-field>
              <v-text-field v-model="userDetails.email" :label="'*' + $t('auth.email')" type="text" :rules="[
                v => !!v || 'שדה חובה',
                v => /.+@.+\..+/.test(v) || 'יש להזין כתובת אימייל תקינה']" :placeholder="$t('auth.email')"
                required></v-text-field>
              <v-text-field v-model="userDetails.phone" :label="$t('auth.phone')" type="number"
                :placeholder="$t('auth.phone')" hide-spin-buttons></v-text-field>
              <v-text-field v-model="userDetails.password" :label="'*' + $t('auth.password')" :type="show1 ? 'text' : 'password'"
               :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1"
               :rules="[
                v => !!v || 'שדה חובה',
                v => v && v.length >= 9 || 'הסיסמה צריכה להכיל לפחות 9 תווים',
                v => /[a-zA-Z]/.test(v) && /\d/.test(v) || 'הסיסמה צריכה לכלול אותיות באנגלית ומספרים'
              ]" :placeholder="$t('auth.password')" required></v-text-field>
              <div>
                <v-checkbox v-model="userDetails.confirmTerms" :rules="[v => !!v || 'חובה לאשר את התנאים']" required
                  style="margin-bottom: 0px !important; margin-top: 0px !important;">
                  <template #label>
                    <div>
                      {{ $t('auth.i_confirm') }}
                      <!-- Span is used here, but you can style it as you wish -->
                      <span style="color: blue; cursor: pointer; text-decoration: underline;" @click="navigateToTerms">
                        {{ $t('auth.the_conditions') }}
                      </span>
                    </div>
                  </template>
                </v-checkbox>
              </div>
              <span @click="openLogin()">
                {{ $t('auth.already_has_account') }} <a style="color: rgb(10, 10, 197);">{{ $t('auth.move_to_login') }} </a>
              </span>
            </v-card-text>
            <v-card-actions class="justify-center" style="margin-top: 20px; margin-bottom: 10px;">
              <v-btn @click="register()" min-width="140">
                <span>{{ $t('auth.register_bt') }}</span>
              </v-btn>
            </v-card-actions>
            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {

  components: {
    SnackBar
  },

  data: () => ({
    isResponsive: false,
    userDetails: {
      email: "",
      password: "",
      name: "",
      phone: "",
      confirmTerms: false,
      recaptchaToken:""
    },
    show1: false,
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
  }),
  methods: {

    async register() {
      if (this.$refs.form.validate()) {
        this.progressShow = true

        // Wait until reCAPTCHA has been loaded.
        await this.$recaptchaLoaded();

        // Execute reCAPTCHA with action "register" to get token, and then send the token with register request
        this.userDetails.recaptchaToken = await this.$recaptcha('register');
        if (!this.userDetails.recaptchaToken) {
          this.showSnackBar("CAPTCHA validation failed", "red");
          return;
        }

        let MyJSON = JSON.stringify(this.userDetails);
        let api = process.env.VUE_APP_BASE_URL + "/auth/register"

        try {
          const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
          this.progressShow = false;
          const jsonObject = await response.json();
          if (response.status === 429) {
            this.showSnackBar("יותר מדי נסיונות, נסה שוב מאוחר יותר", "red");
          } else if (response.status >= 400) {
            this.showSnackBar("שגיאה ביצירת החשבון: " + jsonObject.message, "red");
          } else if (response.status === 200) {
            this.showSnackBar("ההרשמה בוצעה בהצלחה! נשלח מייל לאימות", "green");
            setTimeout(() => {
              this.$emit('login')
            }, 1700);
          }
        } catch (error) {
          this.showSnackBar("Error Login: " + error, "red");
        }

      }
    },
    openLogin() {
      this.$emit('login')
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    handleResize() {
      // Check if the window width is less than or equal to 600px
      if (window.innerWidth <= 500) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    navigateToTerms() {
      // Programmatically navigate to the terms route
      this.$router.push({ name: 'terms' }); // Use the name or path of your terms route
    },
  },
  watch: {
    $route() {
      document.title = 'Login | Eat Kosher'
    },
  },
  beforeDestroy() {
    // Remove event listener before component is destroyed
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {

  },
  mounted() {
    document.title = 'Login | Eat Kosher'
    if (window.innerWidth <= 500) {
      this.isResponsive = true; // Set to true if screen size is small
    }
    window.addEventListener('resize', this.handleResize);
  },
}
</script>
<style>
@media (max-width: 500px) {
  .d-flex.justify-space-around {
    flex-direction: column;
    align-items: center;
  }

  .d-flex.justify-space-around>* {
    margin-bottom: 1rem;
  }

  .d-flex.flex-wrap {
    flex-wrap: wrap;
  }

  .d-flex.flex-wrap>* {
    flex-basis: 100%;
    margin-bottom: 10px;
    /* Add margin between lines */
  }

  h1 {
    font-size: 1.2em;
  }
}

@media (min-width: 501px) {
  h1 {
    font-size: 1.5em;
  }
}

.filtered-calls-blocked {
  font-weight: bold;
}
</style>
  