<template>
  <v-app class="app">
    <v-main>
      <nav-bar v-if="$route.path !== '/login'" style="margin-bottom: 50px;"></nav-bar>

      <router-view />

      <div style="position: absolute; bottom: 10px; right: 45%;" :style="setAbsoluteX()">
        <span @click="openAccessability()" :style="setMarginLeft(10)" style="text-decoration: underline; cursor: pointer;">{{ $i18n.locale === 'en' ? "Accessibility Statement" : "הצהרת נגישות" }}</span>
        <span @click="openTerms()" style="text-decoration: underline; cursor: pointer;">{{ $i18n.locale === 'en' ? "Terms of Use" : "תנאי השימוש" }}</span>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { mapState } from 'vuex';
import NavBar from '@/components/widgets/navBar.vue'
import Auth from './services/auth.service';

export default {
  data() {
    return {
    };
  },
  components: {
        NavBar
    },
  methods: {
    openAccessability() {
      let access = document.getElementById("enable-toolbar-trigger");
      access.click();
      var he = document.querySelector('.tr-trigger');
      if (he) {
        he.click();
      }
      var statment = document.querySelector('.enable-bottomlinks-statement');
      // If the element exists, simulate a click on it
      if (statment) {
        statment.click();
      }
    },
    openTerms() {
      this.$router.push({ name: "terms" })
    },
    setMarginLeft(pixels) {
      return this.$vuetify.rtl ? `margin-left: ${pixels}px` : `margin-right: ${pixels}px`;
    },
    setAbsoluteX() {
      return this.$vuetify.breakpoint.xsOnly  ? "right: 10%;" : "right: 45%;";
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.isLoggedIn,
    }),
  },
  mounted() {
    document.documentElement.lang = window.location.href.startsWith('https://en.') ? 'en' : 'he';
    if (!Auth.checkAuth()) {
      localStorage.removeItem("token")
    }
  },
}
</script>
<style>
.app{
  overflow-x: hidden !important;
}

</style>
 