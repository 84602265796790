<template>
  <!-- Desktop View -->
  <v-row v-if="$vuetify.breakpoint.width > 1370">
    <!-- Sticky Sidebar -->
    <v-col cols="2">
      <div class="sidebar-container" :class="containerClass">
        <!-- Search Section -->
        <div class="sidebar-section">
          <h2 class="text-h4 font-weight-medium mb-4">{{ $t('home.search_title') }}</h2>
          <v-autocomplete v-model="searchSelected" :items="autoCompleteList" :item-value="item => item"
            :item-text="dynamicItemText" :label="$t('home.search_label')" clearable dense filled rounded hide-details
            class="search-input">
            <template #append>
              <v-icon color="primary">mdi-magnify</v-icon>
            </template>
            <template #item="{ item }">
              <v-row no-gutters>
                <v-col cols="9">{{ dynamicItemText(item) }}</v-col>
                <v-col cols="3" class="hint-text" style="text-align: end;">{{ dynamicHintText(item) }}</v-col>
              </v-row>
            </template>
          </v-autocomplete>
        </div>

        <!-- Quick Actions -->
        <div class="quick-actions">
          <v-hover v-slot="{ hover }">
            <v-card @click="getLocation('clicked')" :elevation="hover ? 8 : 2" class="action-card"
              :class="{ 'on-hover': hover }">
              <div class="action-content">
                <v-icon color="primary" size="24">mdi-map-marker-radius</v-icon>
                <span class="action-text">{{ $t('home.my_location') }}</span>
                <v-menu v-model="menu" :close-on-content-click="false" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" class="settings-btn" color="grey lighten-1">
                      <v-icon small>mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="distance-settings">
                    <v-card-text>
                      <p class="text-subtitle-1 mb-4">
                        {{ $t('home.distance_search') }}: {{ selectedDistance }} {{ $t('home.kilometer') }}
                      </p>
                      <v-slider v-model="selectedDistance" :min="minDistance" :max="maxDistance" step="1"
                        color="primary" track-color="primary lighten-3"></v-slider>
                    </v-card-text>
                    <v-card-actions class="justify-center pa-4">
                      <v-btn color="primary" @click="applySearch" block>
                        {{ $t('home.update_distance') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </v-card>
          </v-hover>

          <v-hover v-slot="{ hover }">
            <v-card @click="getFavoritesList()" :elevation="hover ? 8 : 2" class="action-card"
              :class="{ 'on-hover': hover }">
              <div class="action-content">
                <v-icon color="error" size="24">mdi-heart</v-icon>
                <span class="action-text">{{ $t('home.my_favorites') }}</span>
              </div>
            </v-card>
          </v-hover>
        </div>

        <!-- Filters Section -->
        <div class="filters-section">
          <h3 class="text-h5 font-weight-bold mb-4">{{ $t('home.filter_title') }}</h3>
          <ExpansionPanels :constants="constants" :constantsChoosed="constantsChoosed" :lang="lang"
            @filterChanged="getRestaurantsFiltered" class="filters-panel" />
        </div>

        <!-- Add Restaurant -->
        <div class="add-restaurant">
          <v-btn color="primary" block outlined @click="addRest()" class="add-btn">
            <v-icon left>mdi-plus</v-icon>
            {{ $t('home.missing_restaurant') }}
          </v-btn>
        </div>
      </div>
    </v-col>

    <!-- Main Content -->
    <v-col cols="10" style="margin-top: 80px; padding-right: 50px; padding-left: 50px;">
      <!-- Loading State -->
      <v-row v-if="isLoading">
        <v-col class="d-flex justify-center">
          <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
        </v-col>
      </v-row>

      <!-- Results Count -->
      <v-row v-else class="results-header">
        <v-col cols="12" class="text-center">
          <h2 class="text-h4 primary--text font-weight-medium">
            {{ totalItems }} {{ $t('home.results') }}
          </h2>
          <p v-if="hasFavoritesKashruyot" class="text-caption mt-2">
            {{ $t('home.alert_auto_filter') }}
          </p>
        </v-col>
      </v-row>

      <!-- Skeleton Loading -->
      <v-row v-if="isLoading">
        <v-col v-for="(item, index) in skeltonRestaurants" :key="index" cols="12" sm="6" md="4">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>

      <!-- Restaurant Cards -->
      <cards v-else :restaurants="restaurants" :userFavorites="userFavorites" :location="location" :host="host"
        @reloadFavorites="reloadFavorites" @emitShowSnackBar="emitShowSnackBar" :constants="constants" />

      <!-- Load More -->
      <v-row v-if="restaurants.length < totalItems" class="mt-8">
        <v-col class="text-center">
          <v-btn color="primary" outlined @click="loadMoreRestaurants" :loading="loadMore">
            {{ $t('home.load_more') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>

  <!-- Mobile View -->
  <div v-else class="mobile-container">
    <!-- Mobile Search Header -->
    <div class="mobile-search-header">
      <v-row>
        <v-col cols="12" sm="5" class="search-col">
          <v-autocomplete v-model="searchSelected" :items="autoCompleteList" :item-value="item => item"
            :item-text="dynamicItemText" :label="$t('home.search_label')" outlined dense clearable hide-details
            class="mobile-search-input" append-icon="mdi-magnify" :menu-props="{ bottom: true, offsetY: true }"
            ref="autocomplete" @click.native.prevent="handleClick">
            <template #item="{ item }">
              <v-row no-gutters>
                <v-col cols="9">{{ dynamicItemText(item) }}</v-col>
                <v-col cols="3" class="hint-text">{{ dynamicHintText(item) }}</v-col>
              </v-row>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="auto" class="action-col">
          <v-btn @click="getLocation('clicked')" rounded elevation="2" class="action-btn">
            <v-icon color="primary">mdi-map-marker-radius</v-icon>
            <span class="btn-text">{{ $t('home.my_location_small') }}</span>
            <v-menu v-model="menu" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="primary" class="menu-icon" @click="openBar($event)">
                  mdi-menu-down
                </v-icon>
              </template>
              <v-card class="distance-menu">
                <v-card-text>
                  <p class="distance-text">
                    {{ $t('home.distance_search') }}: {{ selectedDistance }} {{ $t('home.kilometer') }}
                  </p>
                  <v-slider v-model="selectedDistance" :min="minDistance" :max="maxDistance" step="1"
                    color="primary"></v-slider>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn color="primary" @click="applySearch">
                    {{ $t('home.update_distance') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-btn>
        </v-col>

        <v-col cols="auto" class="action-col">
          <v-btn @click="getFavoritesList()" rounded elevation="2" class="action-btn">
            <v-icon color="error">mdi-heart</v-icon>
            <span class="btn-text">{{ $t('home.my_favorites_small') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- Mobile Filter Header -->
    <div class="mobile-filter-header">
      <v-row align="center">
        <v-col cols="6">
          <v-btn @click="filterDialog = true" rounded block class="filter-btn">
            <v-icon left>mdi-filter-variant</v-icon>
            {{ $t('home.filter_title') }}
          </v-btn>
        </v-col>

        <v-col cols="6">
          <h3 class="results-count">
            {{ totalItems }} {{ $t('home.results') }}
          </h3>
        </v-col>
      </v-row>

      <p v-if="hasFavoritesKashruyot" class="filter-notice">
        {{ $t('home.alert_auto_filter') }}
      </p>
    </div>

    <!-- Mobile Content -->
    <div class="mobile-content">
      <!-- Loading State -->
      <v-row v-if="progressShow" class="loading-row">
        <v-col class="d-flex justify-center">
          <v-progress-circular indeterminate color="primary" size="48"></v-progress-circular>
        </v-col>
      </v-row>

      <!-- Skeleton Loading -->
      <v-row v-else-if="isLoading">
        <v-col v-for="(item, index) in skeltonRestaurants" :key="index" cols="12" class="d-flex justify-center">
          <v-skeleton-loader width="100%" height="200px" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>

      <!-- Restaurant Cards -->
      <cards v-else :restaurants="restaurants" :userFavorites="userFavorites" :location="location" :host="host"
        @reloadFavorites="reloadFavorites" @emitShowSnackBar="emitShowSnackBar" :constants="constants" />

      <!-- Load More -->
      <div v-if="restaurants.length < totalItems" class="load-more">
        <v-btn color="primary" outlined @click="loadMoreRestaurants" :loading="loadMore" block>
          {{ $t('home.load_more') }}
        </v-btn>
      </div>
    </div>

    <!-- Filter Dialog -->
    <FilterDialog v-model="filterDialog" v-if="filterDialog" :constants="constants" @ressetChoosed="ressetChoosed"
      :lang="lang" :constantsChoosed="constantsChoosed" @searchFiltered="getRestaurantsFiltered" />
  </div>
</template>
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar'
import Distance from '@/util/Distance'
import FilterResults from '@/util/FilterResaults'
import Constants from '@/util/GetConstant'
import Favorites from '@/util/Favorites'
import FavoritesKashruyot from '@/util/getUserFavoritesKashruyot'
import Auth from '@/services/auth.service.js'
// import AdSense from '@/components/widgets/AdSense.vue';
import Cards from '@/components/home-rest-list/Cards.vue';
import ExpansionPanels from '@/components/home-rest-list/ExpansionPanels.vue';
import FilterDialog from '@/components/home-rest-list/dialogs/FilterDialog'

export default {
  components: {
    SnackBar,
    // AdSense,
    Cards,
    ExpansionPanels,
    FilterDialog,
  },
  data() {
    return {
      selectZone: [],
      selectActive: [],
      progressShow: false,
      search: "",
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      restaurants: [],
      skeltonRestaurants: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      itemsPerLoad: 20,
      totalItems: 0,
      autoCompleteList: [],
      searchSelected: "",
      currentPage: 1,
      loadMore: false,
      filterd: false,
      locationClicked: false,
      location: {
        latitude: 0,
        longitude: 0,
      },
      menu: false,
      filterResults: false,
      selectedDistance: 10, // Default selected distance in km
      minDistance: 1,      // Minimum distance value
      maxDistance: 30,     // Maximum distance value
      constants: {
        categories: {},
        sub_categories: {},
        types: {},
        kashruyot: {},
      },
      constantsChoosed: {
        categories: {},
        sub_categories: {},
        types: {},
        kashruyot: {}
      },
      dataType: "",
      host: "",
      isLoading: true,
      initialized: false,
      userFavorites: [""],
      lang: this.$i18n.locale,
      hasFavoritesKashruyot: false,
      filterDialog: false,
    }
  },
  methods: {
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    loadMoreRestaurants() {
      this.$store.commit('currentScrollTop', window.scrollY); // save the current position for load more

      // Check if there are more restaurants to load
      this.loadMore = true;
      if (this.dataType === "name") {
        this.getRestaurantsByName();
      }
      if (this.dataType === "random") {
        this.getRandomRestaurants();
      }
      if (this.dataType === "location") {
        this.getRestaurantsByLocation();
      }
      // Wait until the next DOM update tick
      this.$nextTick(() => {
        this.loadMore = false; // Now set loadMore to false
      });
    },
    async getRestaurantsByName() {
      try {
        this.isLoading = true
        this.progressShow = true;
        if (!this.dataType) {
          this.restaurants = [];
        }

        const name = this.searchSelected;
        const keys = Object.keys(name);
        let key = keys[0];
        let formatedKey = keys[0];
        let value = name[key];
        if (key === "country" || key === "city") {
          value = value.key
          formatedKey = formatedKey + "_web";
        }
        const nameSearchJSON = JSON.stringify({
          searchSelected: { [formatedKey]: value }, filteredChoosed: this.constantsChoosed,
          startfrom: this.restaurants.length, itemsPerLoad: this.itemsPerLoad, platform: "website"
        });
        let api = process.env.VUE_APP_BASE_URL + `/web/get_restaurants_by_name`;

        const res = await fetch(api, ApiServices.requestOptions("POST", nameSearchJSON, ""));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
          this.progressShow = false;
        } else if (res.status === 200) {
          this.restaurants.push(...jsonObject.restaurants);
          this.totalItems = jsonObject.totalCount;
          this.restaurants = Distance.sortByDistance(this.restaurants);
          this.isLoading = false
          this.progressShow = false;
          this.dataType = "name";
          if (Object.keys(this.$route.query).length === 0 || (this.$route.query.key !== key || this.$route.query.value !== value)) {
            // Push the key and value to the query
            this.$router.push({ query: { ...this.$route.query, key: key, value: value } });
          }
          // move the scroll to the previous position after adding restaurants
          this.$nextTick(() => {
            this.itemsPerLoad = 20; // reset items per load
            window.scrollTo(0, this.$store.state.currentScrollTop); // restore the position after back from details
          });
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get restaurants list: " + error, "red");
      }
    },
    async getRandomRestaurants() {
      try {
        this.isLoading = true
        this.restaurants = [];
        this.progressShow = true;
        const JsonBody = JSON.stringify({ filteredChoosed: this.constantsChoosed });
        let api = process.env.VUE_APP_BASE_URL + `/web/get_restaurants_list?platform=website`;
        const res = await fetch(api, ApiServices.requestOptions("POST", JsonBody, ""));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.progressShow = false;
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.restaurants = jsonObject;
          this.totalItems = jsonObject.length;
          this.isLoading = false
          this.progressShow = false;
          this.dataType = "random";
          this.$nextTick(() => {
            window.scrollTo(0, this.$store.state.currentScrollTop); // For page scroll
          });
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get restaurants list: " + error, "red");
      }
    },
    async getRestaurantsByLocation() {
      try {
        this.isLoading = true
        this.progressShow = true;
        if (!this.dataType) {
          this.restaurants = [];
        }
        const locationJSON = JSON.stringify({
          location: this.location, distance: this.selectedDistance, filteredChoosed: this.constantsChoosed,
          startfrom: this.restaurants.length, itemsPerLoad: this.itemsPerLoad, platform: "website"
        });

        let api = process.env.VUE_APP_BASE_URL + "/web/get_restaurants_list_by_location";
        const res = await fetch(api, ApiServices.requestOptions("POST", locationJSON, ""));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.progressShow = false;
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.restaurants.push(...jsonObject.restaurants);
          this.totalItems = jsonObject.totalCount;
          this.dataType = "location";
          this.isLoading = false
          this.progressShow = false;
          // Reset the scroll to the previous position after adding restaurants
          this.$nextTick(() => {
            this.itemsPerLoad = 20;
            window.scrollTo(0, this.$store.state.currentScrollTop); // restore the position after back from details
          });
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get restaurants list: " + error, "red");
      }
    },
    async getRestaurantsFiltered() {
      if (this.dataType === "name") {
        this.dataType = "";
        this.getRestaurantsByName();
      }
      if (this.dataType === "random") {
        this.dataType = "";
        this.getRandomRestaurants();
      }
      if (this.dataType === "location") {
        this.dataType = "";
        this.getRestaurantsByLocation();
      }
      if (this.dataType === "favorites") {
        this.dataType = "";
        this.getUserFavorites();
      }
      // add filters to params
      if (this.dataType !== "favorites") {
        try {
          let filters = FilterResults.filterResults(this.restaurants, this.constantsChoosed);
          const filtersParams = filters.filtersParams;
          // Merge current query with new filtersParams
          const newQuery = { ...this.$route.query, ...filtersParams };
          // Check if the current query is different from the new query
          if (JSON.stringify(this.$route.query) !== JSON.stringify(newQuery)) {
            // Push only if the new query is different
            this.$router.push({ query: newQuery });
          }
        } catch (error) {
        }
      }
    },
    async getFavoritesList() {
      try {
        this.isLoading = true
        this.restaurants = [];
        this.progressShow = true;
        const favoritesJSON = JSON.stringify({ favoritesList: this.userFavorites });
        let api = process.env.VUE_APP_BASE_URL + `/web/get_favorites_list`;
        const res = await fetch(api, ApiServices.requestOptions("POST", favoritesJSON, ""));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.restaurants = jsonObject;
          this.totalItems = jsonObject.length;
          this.dataType = "favorites";
          this.isLoading = false
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get favorites list: " + error, "red");
      }

    },
    ressetChoosed() {
      this.constantsChoosed = {
        categories: [],
        sub_categories: [],
        types: [],
        kashruyot: []
      }
      this.getRestaurantsFiltered();
    },
    setFiltersFromParams() {
      if (this.$route.query.fs_categories) {
        const categories = this.$route.query.fs_categories.split(',');
        this.constantsChoosed.categories = Object.entries(this.constants.categories)
          .filter(([key, value]) => categories.includes(key))
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
      }
      if (this.$route.query.fs_sub_categories) {
        const sub_categories = this.$route.query.fs_sub_categories.split(',');
        this.constantsChoosed.sub_categories = Object.entries(this.constants.sub_categories)
          .filter(([key, value]) => sub_categories.includes(key))
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
      }
      if (this.$route.query.fs_kashrut) {
        const kashruyot = this.$route.query.fs_kashrut.split(',');
        this.constantsChoosed.kashruyot = Object.entries(this.constants.kashruyot)
          .filter(([key, value]) => kashruyot.includes(key))
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
      }
      if (this.$route.query.fs_types) {
        const types = this.$route.query.fs_types.split(',');
        this.constantsChoosed.types = Object.entries(this.constants.types)
          .filter(([key, value]) => types.includes(key))
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
      }
    },
    handleClick() {
      this.$refs.autocomplete.isMenuActive = false; // This opens the menu
    },
    getLocation(clicked) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.showPosition(position);
            { true }
            this.getRestaurantsByLocation();
          },
          error => {
            if (clicked) {
              //  console.log(error);
              return this.showSnackBar("שגיאה בקבלת המיקום: יש להפעיל את המיקום בלחיצה על האייקון למעלה בשורת הכתובת", "red");
            }
            this.getRandomRestaurants();
          }
        );
      } else {
        this.showSnackBar("Geolocation is not supported", "red");
      }
    },
    showPosition(position) {
      this.location.latitude = position.coords.latitude;
      this.location.longitude = position.coords.longitude;
    },
    dynamicItemText(item) {
      // Assuming item has at least one key
      const keys = Object.keys(item);
      const firstKey = keys[0];
      if (firstKey === "name") {
        return item[firstKey];
      }
      return item[firstKey][this.$i18n.locale];
    },

    dynamicHintText(item) {
      // Assuming item has at least one key
      const keys = Object.keys(item);
      const firstKey = keys[0];
      const keysTranslate = [{ he: "מדינה", en: "country" }, { he: "עיר", en: "city" }, { he: "שם", en: "name" }];
      // Assuming $i18n.locale is available in your Vue instance
      // Find the translation object that matches the firstKey
      const translation = keysTranslate.find(k => k.en.toLowerCase() === firstKey);
      // Return the translated value if found, otherwise return the original key
      const translatedText = translation ? translation[this.$i18n.locale === 'he' ? 'he' : 'en'] : firstKey;
      return translatedText;
    },
    openBar(event) {
      // Prevent the click event from propagating to the v-btn
      event.stopPropagation();
      this.menu = !this.menu
      // Implement your menu down icon click action here
    },
    applySearch() {
      this.menu = false; // Close the menu after applying the search
      this.getRestaurantsByLocation();
    },

    async getConstants() {
      if (this.$store.state.constant?.categories?.restaurant) {
        this.constants = this.$store.state.constant
      } else {
        const constants = await Constants.getConstant();
        if (!constants) {
          this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
        }
        this.constants = constants
        this.$store.state.constant = constants;
      }
    },
    async getUserFavoritesKashruyot() {
      if (localStorage.getItem("token")) {
        const favoritesKashruyot = await FavoritesKashruyot.getUserFavoritesKashruyot();
        if (favoritesKashruyot && favoritesKashruyot.length > 0) {
          this.hasFavoritesKashruyot = true;
          for (let i = 0; i < favoritesKashruyot.length; i++) {
            this.constantsChoosed.kashruyot[favoritesKashruyot[i]] = this.constants.kashruyot[favoritesKashruyot[i]];
          }
        }
      }
    },
    async getSearchAutoCompleteList() {
      const autoCompletes = await Constants.getSearchAutoCompleteList();
      if (!autoCompletes) {
        this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
      }
      this.autoCompleteList = autoCompletes;
    },

    async getUserFavorites() {
      let auth = Auth.checkAuth();
      if (!auth) {
        return this.userFavorites = JSON.parse(localStorage.getItem('favorites') || '[]');
      }
      this.userFavorites = await Favorites.getUserFavorites();
    },

    addRest() {
      let auth = Auth.checkAuth();
      if (auth) {
        this.$router.push({ name: "userAccount", params: { addRest: true } })
      } else {
        this.$router.push({ name: "login" })
      }
    },
    loadAdSenseScript() {
      return new Promise((resolve, reject) => {
        if (document.querySelector('script[src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]')) {
          resolve();
          return;
        }
        const script = document.createElement('script');
        script.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    reloadFavorites() {
      this.getUserFavorites();
    },
    emitShowSnackBar(message, color) {
      this.showSnackBar(message, color);
    },
    setMarginRigth(pixels) {
      return this.$vuetify.rtl ? `margin-right: ${pixels}px` : `margin-left: ${pixels}px`;
    },
    setMarginLeft(pixels) {
      return this.$vuetify.rtl ? `margin-left: ${pixels}px` : `margin-right: ${pixels}px`;
    },
    async getRsetOnMounted() {
      await this.getConstants();
      // get user favorites kashruyot to filter
      if (localStorage.getItem("token")) {
        await this.getUserFavoritesKashruyot();
        this.getRestaurantsFiltered();
      }

      this.setFiltersFromParams();
      if (!this.$route.query.key || !this.$route.query.value) {
        this.getLocation(null);
      } else {
        // set query to search
        if (this.$route.query.key === "country") {
          this.searchSelected = {
            country: {
              ...this.constants.countries[this.$route.query.value], // Spread the properties of the country object
              key: this.$route.query.value // Add the new field 'key' to the object
            }
          };
        } else if (this.$route.query.key === "city") {
          this.searchSelected = {
            city: {
              ...this.constants.cities[this.$route.query.value],
              key: this.$route.query.value
            }
          };
        } else {
          this.searchSelected = {
            [this.$route.query.key]: this.$route.query.value
          };
        }

      }

    }
    // handleScroll() {
    //   // Calculate the scroll position for the entire page
    //   const scrollPosition = window.innerHeight + window.scrollY;
    //   const pageHeight = document.documentElement.offsetHeight;
    //   console.log(333333);

    //   if (scrollPosition >= pageHeight) {
    //     console.log(444444444);
    //     this.loadMoreRestaurants(); // Action when bottom is reached
    //   }
    // },
  },
  computed: {
    containerClass() {
      return this.$vuetify.rtl ? 'container-rtl' : 'container-ltr';
    }
  },
  watch: {
    searchSelected() {
      this.dataType = "";
      if (this.searchSelected) {
        this.getRestaurantsByName();
      } else {
        // clean the query
        this.$router.push({ query: {} });
        this.getLocation(null);
      }
    },
    '$i18n.locale'(newLocale) {
      this.lang = newLocale;
      // You can add any additional logic here, for example:
    }
  },
  mounted() {
    // this.loadAdSenseScript().then(() => {
    //   (adsbygoogle = window.adsbygoogle || []).push({});
    // }).catch((error) => {
    //   console.error('Failed to load AdSense script:', error);
    // });
    this.getRsetOnMounted();

    this.host = process.env.VUE_APP_BASE_URL;

    this.getSearchAutoCompleteList();
    this.getUserFavorites();

    // this is for restore the scroll position else it same (20)
    this.itemsPerLoad = this.$store.state.itemsPerLoad;
  },
}
</script>
<style scoped>
.sidebar-container {
  position: fixed;
  top: 71px;
  /* top: 0; */
  height: 93%;
  width: inherit;
  max-width: inherit;
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  overflow-y: auto;
  /* z-index: 100; */
}

.sidebar-container.container-rtl {
  right: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: none;
}

.sidebar-container.container-ltr {
  left: 0;
}

.sidebar-section {
  margin-bottom: 2rem;
}

.search-input {
  background: white;
  border-radius: 8px;
}

.quick-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 2rem;
}

.action-card {
  position: relative;
  height: 100px;
  border-radius: 12px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.action-card.on-hover {
  transform: translateY(-4px);
}

.action-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.action-text {
  margin-top: 0.5rem;
  font-weight: 500;
}

.settings-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0.7;
}

.distance-settings {
  width: 300px;
  border-radius: 12px;
}

.filters-section {
  flex: 1;
  margin-bottom: 2rem;
  overflow-y: auto;
}

.filters-panel {
  background: transparent !important;
}

.add-restaurant {
  margin-top: auto;
  padding-top: 1rem;
}

.add-btn {
  border-radius: 8px;
  height: 48px;
  text-transform: none;
  font-weight: 500;
}

.results-header {
  margin-bottom: 2rem;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #666;
}

/* Hint Text */
.hint-text {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  text-align: right;
}

/* Mobile Styles */
.mobile-container {
  padding: 1rem;
}

.mobile-search-header {
  background: white;
  border-radius: 16px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.search-col {
  max-width: 400px;
}

.mobile-search-input {
  background: white;
  border-radius: 8px;
}

.action-col {
  max-width: 130px;
}

.action-btn {
  height: 40px;
  text-transform: none;
  font-weight: 500;
  background: white !important;
}

.btn-text {
  margin: 0 0.5rem;
  white-space: nowrap;
}

.menu-icon {
  margin-left: 4px;
}

.distance-menu {
  width: 300px;
  border-radius: 12px;
}

.distance-text {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.mobile-filter-header {
  background: white;
  border-radius: 16px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.filter-btn {
  text-transform: none;
  font-weight: 500;
  background: #f5f5f5 !important;
}

.results-count {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--v-primary-base);
  margin: 0;
  text-align: right;
}

.filter-notice {
  text-align: center;
  font-size: 0.875rem;
  color: #666;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.mobile-content {
  background: white;
  border-radius: 16px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.loading-row {
  min-height: 200px;
  display: flex;
  align-items: center;
}

.load-more {
  margin-top: 1rem;
  padding: 0 1rem;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .mobile-container {
    padding: 0.5rem;
  }

  .action-btn {
    min-width: 0;
    padding: 0 12px;
  }

  .btn-text {
    display: none;
  }

  .results-count {
    font-size: 1rem;
  }
}

@media (max-width: 1370px) {
  .sidebar-container {
    position: relative;
    height: auto;
    width: 100%;
  }
}
</style>