<template>
    <v-app style="background-color: rgb(238, 242, 246);">
        <v-main>
            <div
                style="height: 50px; margin-top: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
                <v-tabs v-model="activeTab" centered background-color="#dae1eb" height="50px">
                    <v-tab v-for="tab in tabs" :key="tab" class="text-center" @click="setComponent(tab)">
                        <span style="font-weight: bold;">{{ tab }}</span>
                    </v-tab>
                </v-tabs>
            </div>
            <EditRestaurant v-if="editRestaurant"></EditRestaurant>
            <addRestaurant v-if="addRestaurant"></AddRestaurant>
            <adminSettings v-if="adminSettings"></adminSettings>
        </v-main>
    </v-app>
</template>
<script>
import AddRestaurant from '@/components/admin/AddRestaurant.vue'
import EditRestaurant from '@/components/admin/editRest/EditRestaurant'
import adminSettings from '@/components/admin/SettingsComp'
import Auth from '@/services/auth.service.js'

export default {
    components: {
        AddRestaurant,
        EditRestaurant,
        adminSettings,
    },
    data() {
        return {
            addRestaurant: true,
            editRestaurant: false,
            activeTab: 0,
            adminSettings: false,
            tabs: ["הוספת מסעדה חדשה", "עריכת מסעדה", "הגדרות מנהל"]
        }
    },
    methods: {
        setComponent(tab) {
            if (tab == "הגדרות מנהל") {
                this.addRestaurant = false;
                this.editRestaurant = false;
                this.adminSettings = true;
            } else if (tab == "הוספת מסעדה חדשה") {
                this.addRestaurant = true;
                this.editRestaurant = false;
                this.adminSettings = false;
            } else if (tab == "עריכת מסעדה") {
                this.addRestaurant = false;
                this.editRestaurant = true;
                this.adminSettings = false;
            }
        }
    },
    mounted() {
        document.title = 'כשר בסביבה | ניהול '
        let auth = Auth.checkAuth();
        if (!auth) {
            this.$router.replace({ name: "home" })
        }

        if (this.$route.params.tab) {
            this.setComponent(this.$route.params.tab);
            this.activeTab = 1
        }
    },

}
</script>
<style scoped>
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    margin-right: 5px;
    margin-left: 5px;
}
</style>