<template>
  <v-row class="d-flex justify-center mx-0 my-4">
    <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, index) in restaurants" :key="item._id">
      <v-hover v-slot="{ hover }">
        <v-card
          class="restaurant-card mx-auto"
          :elevation="hover ? 8 : 2"
          :class="{ 'on-hover': hover }"
        >
          <!-- Image Section -->
          <div class="image-container">
            <clazy-load :src="host + '/logo' + item.logo" v-if="item.logo">
              <v-img
                :src="host + '/logo' + item.logo"
                height="200"
                @click="showDetails(item, 'card-' + index)"
                class="restaurant-image"
                gradient="to bottom, rgba(0,0,0,0.03), rgba(0,0,0,0.2)"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-linear
                      indeterminate
                      color="grey lighten-2"
                      class="mb-0"
                    />
                  </v-row>
                </template>
              </v-img>
              <div slot="placeholder" class="d-flex justify-center align-center" style="height: 200px">
                <v-progress-circular
                  indeterminate
                  color="primary"
                />
              </div>
            </clazy-load>
            <v-img
              v-else
              height="200"
              src="@/assets/default_logo_clear.png"
              contain
              class="restaurant-image"
              gradient="to bottom, rgba(0,0,0,0.05), rgba(0,0,0,0.3)"
              @click="showDetails(item, 'card-' + index)"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-linear
                    indeterminate
                    color="grey lighten-2"
                    class="mb-0"
                  />
                </v-row>
              </template>
            </v-img>

            <!-- Overlay Elements -->
            <div class="image-overlay" :class="{ 'lighter-overlay': item.logo }">
              <!-- Status Badges -->
              <div class="status-badges">
                <v-chip v-if="item.open_hours.only_weekend" x-small class="status-chip weekend">
                  {{ $t('card.open_only_weekend') }}
                </v-chip>
                <v-chip v-if="isPromotionActive(item)" x-small class="status-chip promotion">
                  {{ $t('card.promoted') }}
                </v-chip>
                <v-chip v-if="item.open_hours.isTemporarilyClosed" x-small class="status-chip closed">
                  {{ $t('card.isTemporarilyClosed') }}
                </v-chip>
              </div>

              <!-- Favorite Button -->
              <v-btn
                icon
                class="favorite-btn"
                @click.stop="addOrRemoveToFavorite(item, isFavorite(item))"
              >
                <v-icon :color="isFavorite(item) ? 'pink' : 'white'">
                  {{ isFavorite(item) ? 'mdi-heart' : 'mdi-heart-outline' }}
                </v-icon>
              </v-btn>

              <!-- Restaurant Name -->
              <h3 class="restaurant-title text-h6 font-weight-bold white--text">
                {{ item.name }}
              </h3>
            </div>
          </div>

          <!-- Content Section -->
          <v-card-text :class="{'promotion-bg': isPromotionActive(item)}" >
            <!-- Rating & Category -->
            <div class="d-flex justify-space-between align-center mb-3">
              <div class="rating-container">
                <v-rating
                  :value="calculateRates(item.rates.ratings)"
                  readonly
                  dense
                  half-increments
                  color="amber"
                  background-color="amber lighten-3"
                  size="18"
                />
                <span class="text-caption ml-1">({{ item.rates.totalRatings }})</span>
              </div>
              
              <div class="category-badge">
                <v-icon small :color="getBackColorType(item.category_web)">
                  {{ getCategoryIcon(item.category_web) }}
                </v-icon>
                <span class="text-caption ml-1">{{ constants.categories[item.category_web][$i18n.locale] }}</span>
              </div>
            </div>

            <!-- Type Tags -->
            <div class="type-tags mb-3">
              <v-chip
                v-for="(type, typeIndex) in item.type_web.slice(0, 3)"
                :key="typeIndex"
                x-small
                :color="getBackColorType(type)"
                text-color="white"
                class="mr-1"
              >
                {{ constants.types[type][$i18n.locale] }}
              </v-chip>
            </div>

            <!-- Location Info -->
            <div class="location-info">
              <div class="d-flex align-center mb-2">
                <v-icon small color="grey darken-1">mdi-map-marker</v-icon>
                <div class="ml-2 text-truncate-container  mr-1">
                  <span class="text-body-2 text-truncate">{{ item.address }}</span>
                  <span class="text-caption grey--text">
                    {{ constants.cities?.[item.city_web]?.[$i18n.locale] }}
                    {{ item.country_web ? ', ' : '' }}
                    {{ constants.countries?.[item.country_web]?.[$i18n.locale] || '' }}
                  </span>
                </div>
              </div>

              <div class="d-flex align-center mb-2">
                <v-icon small color="grey darken-1">mdi-road</v-icon>
                <span class="text-body-2 ml-2 mr-1">{{ calculateDistance(item) }}</span>
              </div>

              <div class="d-flex align-center">
                <v-icon small color="grey darken-1">mdi-scale-balance</v-icon>
                <span class="text-body-2 ml-2  mr-1">
                  {{ item.kashrut.kashrut_name_web === "other" ? $t('card.kashrut') + ": " : '' }}
                  {{ constants.kashruyot[item.kashrut.kashrut_name_web][$i18n.locale] }}
                </span>
              </div>
            </div>

            <!-- View Details Button -->
            <v-btn
              text
              block
              color="primary"
              class="mt-3"
              @click="showDetails(item, 'card-' + index)"
            >
              {{ $t('card.full_details') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>
<script>
import Auth from '@/services/auth.service.js'
import Favorites from '@/util/Favorites'
import Distance from '@/util/Distance'

export default {
  name: "CardComponent",
  props: {
      restaurants: Array,
      userFavorites: Array,
      location: Object,
      host: String,
      constants: Object
  },
  data() {
      return {
          currentScrollTop: 0 // For page scroll
      }
  },
  methods: {

      isPromotionActive(item) {
          const currentDate = new Date();
          return item.promotion.isPromotion && new Date(item.promotion.dateEnd) > currentDate;
      },
      showDetails(item) {
          this.$store.commit('currentScrollTop', window.scrollY); // For page scroll
          this.$store.commit('itemsPerLoad', this.restaurants.length); // For page scroll
          this.$router.push({ name: 'restaurant-details', params: { id: item._id } });
      },
      getBackgroundColor(item) {
          const currentDate = new Date();
          const isPromotionValid = item.promotion.isPromotion && new Date(item.promotion.dateEnd) > currentDate;
          return isPromotionValid ? { 'background-color': '#f0b6ae73' } : { 'background-color': '#f6f6f6' };
      },
      calculateRates(ratings) {
          const sum = ratings.reduce((total, rating) => total + rating.rate, 0);
          const average = sum / ratings.length;
          return average;
      },
      async addOrRemoveToFavorite(item, isFavorite) {

          let auth = Auth.checkAuth();
          if (!auth) {
              // To save a favorite in local storage
              if (!isFavorite) {
                  const favorites = JSON.parse(localStorage.getItem('favorites') || '[]');
                  favorites.push(item._id);
                  localStorage.setItem('favorites', JSON.stringify(favorites));
                  this.$emit('emitShowSnackBar', "נוסף למועדפים", "green");
                  this.$emit('reloadFavorites');
                  return
              } else {
                  // If it's a favorite, remove from local storage
                  const updatedFavorites = JSON.parse(localStorage.getItem('favorites') || '[]').filter(fav => fav !== item._id);
                  localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
                  this.$emit('emitShowSnackBar', "הוסר מהמועדפים", "green");
                  this.$emit('reloadFavorites');
                  return
              }
          }

          const addToFavorite = await Favorites.addOrRemoveToFavorite(JSON.stringify({ item: item, isFavorite: isFavorite }));
          if (addToFavorite === "added") {
              this.$emit('emitShowSnackBar', "נוסף למועדפים", "green");
              this.$emit('reloadFavorites');
          } else if (addToFavorite === "removed") {
              this.$emit('emitShowSnackBar', "הוסר מהמועדפים", "green");
              this.$emit('reloadFavorites');
          } else {
              this.$emit('emitShowSnackBar', "שגיאה בהוספה למועדפים", "red");
          }
      },
      isFavorite(item) {
          return this.userFavorites.includes(item._id);
      },
      getCategoryIcon(category) {
          switch (category) {
              case 'restaurant': return 'mdi-silverware';
              case 'fast_food': return 'mdi-hamburger';
              case 'bakery': return 'mdi-baguette';
              case 'supermarket': return 'mdi-cart';
              default: return 'mdi-store';
          }
      },

      getBackColorType(type) {
          const colors = {
              meat: 'red darken-1',
              dairy: 'blue darken-1',
              parve: 'green darken-1'
          };
          return colors[type] || 'grey';
      },
      calculateDistance(item) {
          let dist;
          if (item.distance) {
              dist = item.distance
          } else {
              dist = Distance.checkDistance(this.location, item.location);
          }
          if (dist < 1000) {
              return `${dist} ${this.$t('card.meter')}`;
          } else {
              const firstPart = Math.floor(dist / 1000); // Get the first part of the distance
              const secondPart = Math.floor((dist % 1000) / 100); // Get the second part of the distance

              return `${firstPart}.${secondPart} ${this.$t('card.kilometer')}`;
          }

      },
      setMarginRigth(pixels) {
          return this.$vuetify.rtl ? `margin-right: ${pixels}px` : `margin-left: ${pixels}px`;
      },
      setMarginLeft(pixels) {
          return this.$vuetify.rtl ? `margin-left: ${pixels}px` : `margin-right: ${pixels}px`;
      },
  },
  mounted() {

  },
}
</script>
<style scoped>
.restaurant-card {
height: 100%;
border-radius: 16px;
overflow: hidden;
transition: transform 0.3s ease;
}

.restaurant-card.on-hover {
transform: translateY(-4px);
}

.image-container {
position: relative;
overflow: hidden;
}

.restaurant-image {
transition: transform 0.3s ease;
}

.restaurant-card:hover .restaurant-image {
transform: scale(1.05);
}

.image-overlay {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
padding: 16px;
display: flex;
flex-direction: column;
}

.image-overlay.lighter-overlay {
background: linear-gradient(to bottom, rgba(0,0,0,0.03), rgba(0,0,0,0.2));
}

.status-badges {
align-self: flex-start;
display: flex;
flex-direction: column;
gap: 4px;
}

.status-chip {
font-size: 0.625rem !important;
height: 20px !important;
}

.status-chip.weekend {
background-color: rgba(255, 224, 178, 0.9) !important;
color: #E65100 !important;
}

.status-chip.promotion {
background-color: rgba(225, 190, 231, 0.9) !important;
color: #4A148C !important;
}

.status-chip.closed {
background-color: rgba(255, 205, 210, 0.9) !important;
color: #B71C1C !important;
}

.favorite-btn {
position: absolute;
top: 8px;
inset-inline-end: 8px;
background: rgba(255, 255, 255, 0.1);
backdrop-filter: blur(4px);
}

.restaurant-title {
margin-top: auto;
text-shadow: 0 2px 4px rgba(0,0,0,0.4);
}

.rating-container {
display: flex;
align-items: center;
}

.category-badge {
display: flex;
align-items: center;
background: rgba(0, 0, 0, 0.05);
padding: 4px 8px;
border-radius: 12px;
}

.type-tags {
display: flex;
flex-wrap: wrap;
gap: 4px;
}

.location-info {
background: rgba(0, 0, 0, 0.02);
border-radius: 8px;
padding: 12px;
}

.text-truncate-container {
min-width: 0;
max-width: 100%;
display: flex;
flex-direction: column;
}

.text-truncate {
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.promotion-bg {
background-color: rgba(240, 182, 174, 0.1);
}
</style>