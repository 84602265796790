var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"margin-bottom":"70px"}},[_c('v-row',{staticClass:"mt-10 d-flex justify-center"},[_c('v-col',{staticStyle:{"max-width":"500px"},attrs:{"cols":"6"}},[_c('v-card',{staticClass:"pa-4",staticStyle:{"box-shadow":"0 0 10px #fff"}},[_c('v-img',{staticClass:"my-3 mx-auto",style:({ 'width': _vm.isResponsive ? '60%' : '100%' }),attrs:{"src":require('../../assets/default_logo.png'),"contain":"","height":"200"}}),_c('div',[_c('span',{staticClass:"d-flex justify-center"},[_c('h1',{staticStyle:{"color":"#1e517b"}},[_vm._v(_vm._s(_vm.$t('auth.register_title')))])])]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler.apply(null, arguments)}}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":'*' + _vm.$t('auth.name'),"type":"text","rules":[v => !!v || 'שדה חובה'],"placeholder":_vm.$t('auth.name'),"required":""},model:{value:(_vm.userDetails.name),callback:function ($$v) {_vm.$set(_vm.userDetails, "name", $$v)},expression:"userDetails.name"}}),_c('v-text-field',{attrs:{"label":'*' + _vm.$t('auth.email'),"type":"text","rules":[
              v => !!v || 'שדה חובה',
              v => /.+@.+\..+/.test(v) || 'יש להזין כתובת אימייל תקינה'],"placeholder":_vm.$t('auth.email'),"required":""},model:{value:(_vm.userDetails.email),callback:function ($$v) {_vm.$set(_vm.userDetails, "email", $$v)},expression:"userDetails.email"}}),_c('v-text-field',{attrs:{"label":_vm.$t('auth.phone'),"type":"number","placeholder":_vm.$t('auth.phone'),"hide-spin-buttons":""},model:{value:(_vm.userDetails.phone),callback:function ($$v) {_vm.$set(_vm.userDetails, "phone", $$v)},expression:"userDetails.phone"}}),_c('v-text-field',{attrs:{"label":'*' + _vm.$t('auth.password'),"type":_vm.show1 ? 'text' : 'password',"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"rules":[
              v => !!v || 'שדה חובה',
              v => v && v.length >= 9 || 'הסיסמה צריכה להכיל לפחות 9 תווים',
              v => /[a-zA-Z]/.test(v) && /\d/.test(v) || 'הסיסמה צריכה לכלול אותיות באנגלית ומספרים'
            ],"placeholder":_vm.$t('auth.password'),"required":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.userDetails.password),callback:function ($$v) {_vm.$set(_vm.userDetails, "password", $$v)},expression:"userDetails.password"}}),_c('div',[_c('v-checkbox',{staticStyle:{"margin-bottom":"0px !important","margin-top":"0px !important"},attrs:{"rules":[v => !!v || 'חובה לאשר את התנאים'],"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.$t('auth.i_confirm'))+" "),_c('span',{staticStyle:{"color":"blue","cursor":"pointer","text-decoration":"underline"},on:{"click":_vm.navigateToTerms}},[_vm._v(" "+_vm._s(_vm.$t('auth.the_conditions'))+" ")])])]},proxy:true}]),model:{value:(_vm.userDetails.confirmTerms),callback:function ($$v) {_vm.$set(_vm.userDetails, "confirmTerms", $$v)},expression:"userDetails.confirmTerms"}})],1),_c('span',{on:{"click":function($event){return _vm.openLogin()}}},[_vm._v(" "+_vm._s(_vm.$t('auth.already_has_account'))+" "),_c('a',{staticStyle:{"color":"rgb(10, 10, 197)"}},[_vm._v(_vm._s(_vm.$t('auth.move_to_login'))+" ")])])],1),_c('v-card-actions',{staticClass:"justify-center",staticStyle:{"margin-top":"20px","margin-bottom":"10px"}},[_c('v-btn',{attrs:{"min-width":"140"},on:{"click":function($event){return _vm.register()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('auth.register_bt')))])])],1),(_vm.progressShow)?_c('v-row',{staticClass:"my-5"},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_vm._e()],1)],1)],1)],1),_c('SnackBar',{attrs:{"snacbarText":_vm.snacbarText,"snackbarColorBt":_vm.snackbarColorBt},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }